<template>
  <div class="list-import-trade-in">
    <Loader
      v-if="loaderEnabled"
      v-bind:logo="loaderLogo"
    ></Loader>
    <KTCodePreview v-bind:title="'Danh sách phiếu thu cũ'">
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <div class="d-flex justify-content-center align-items-center">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs.start"
                v-model="dpForm.startDate"
              ></date-picker>
              <span class="ml-1 mr-1"></span>
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs.end"
                v-model="dpForm.endDate"
              ></date-picker>
            </div>

          </b-col>
          <b-col>
            <BDropdownCustom
              v-model="valueStore"
              :options="filterdOptionsStore"
              :placeholder="'cửa hàng'"
              :searchable="true"
              :matchKey="['name']"
              :limit="5"
            />
          </b-col>
          <b-col>
            <Autosuggest
              :model="searchProduct"
              :suggestions="filteredOptionsProduct"
              placeholder="mã, tên, mã vạch sản phẩm"
              :limit="10"
              @select="onSelectedProduct"
              @change="onInputChangeProduct"
              suggestionName="productName"
            />
          </b-col>
          <b-col>
            <b-form-input
              v-model="searchDesciption"
              type="text"
              placeholder="Tìm kiếm theo nội dung"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="selectedCompany"
              :options="listCompany"
              value-field="id"
              text-field="name"
              v-on:change="onChangeCompany"
            >
              <template v-slot:first>
                <b-form-select-option
                  :value="null"
                  disabled
                >-- Doanh nghiệp --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input
              v-model="searchCode"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
            >Lọc</b-button>
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="items"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table
              :speed="5"
              :animate="true"
              :columns="10"
            ></vcl-table>
          </template>

          <template v-slot:cell(count)="row">
            <div style="text-align: center">
              <span
                v-text="row.item.count"
                class="text-right"
              ></span>
            </div>
          </template>

          <template v-slot:cell(code)="row">
            <div
              class="productCode"
              @click="editItem(row.item)"
              style="cursor: pointer"
            >
              <span v-text="row.item.code"></span>
            </div>
          </template>

          <template v-slot:cell(relateStockSlipId)="row">
            <div
              class="productCode"
              @click="linkToBill(row.item)"
              style="cursor: pointer"
            >
              <span v-text="row.item.relateStockSlipId"></span>
            </div>
          </template>

          <template v-slot:cell(importDate)="row">
            <div style="text-align: center">
              <span
                v-text="row.item.importDate"
                class="text-right text-primary mb-0"
              ></span>
            </div>
          </template>
          <template v-slot:cell(sourceWarehouseId)="row">
            <span
              v-text="row.item.sourceWarehouseId"
              class="font-weight-bolder"
            ></span>
            <br />
            <span v-if="row.item.companyName">( {{ row.item.companyName }} )</span>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown
                size="sm"
                id="dropdown-left"
                no-caret
                right
              >
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="printItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-printer"
                    ></i>
                    &nbsp; In phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="editItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-pen"
                    ></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('STOCK_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng số:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              v-model="page"
              class="customPagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchStock"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import moment from 'moment';
import { IMPORT_STOCK, TIME_TRIGGER } from '@/utils/constants';
import { vi } from 'vuejs-datepicker/dist/locale';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
import decounce from 'debounce';
import localData from '@/utils/saveDataToLocal';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { cloneDeep, map, find, assign } from 'lodash';
import { removeAccents } from '@/utils/common';
import { mapGetters } from 'vuex';
import Loader from '@/view/content/Loader.vue';
import { getToastInstance } from '@/utils/toastHelper';
import BDropdownCustom from '@/view/base/bootstrap/BDropdownCustom.vue';

export default {
  data() {
    return {
      dpForm: {
        startDate: '',
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      format: 'dd-MM-yyyy',
      language: vi,
      items: [],
      searchDesciption: '',
      searchCode: '',
      currentPage: 0,
      totalPages: 1,
      totalItems: 0,
      page: 1,
      searchFromDay: '',
      searchToDay: '',
      type: IMPORT_STOCK,
      fields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            textAlign: 'center',
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'sttClass',
        },
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '18%' },
        },
        {
          key: 'sourceWarehouseId',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '35%' },
        },
        {
          key: 'importDate',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'createBy',
          label: 'Người tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        { key: 'actions', label: '', thStyle: { width: '5%' } },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      limit: 10,
      searchProduct: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      optionsStore: [],
      selectedCompany: null,
      listCompany: [],
      valueStore: [],
      filterdOptionsStore: [],
    };
  },
  methods: {
    ...getToastInstance(),
    fetchStore: async function () {
      this.optionsStore = [];
      ApiService.get(
        `/stores/getStores?companyId=${this.selectedCompany}`,
      ).then((response) => {
        const stores = response.data.data;
        this.optionsStore = stores.map((element) => {
          return {
            code: element.id,
            name: element.name,
            shortName: element.shortName,
            checked: false,
          };
        });
        this.filterdOptionsStore = cloneDeep(this.optionsStore);
      });
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.optionsStore = [];
          const stores = data.data.stores;
          stores.forEach((element) => {
            let option = {
              code: element.id,
              name: element.name,
              shortName: element.shortName,
              checked: false,
            };
            this.optionsStore.push(option);
            if (!data.data.viewAll) {
              let index = this.optionsStore.findIndex(
                (item) => item.code == element.id,
              );
              this.optionsStore[index].checked = true;
              this.valueStore.push(this.optionsStore[index]);
            }
          });
          this.filterdOptionsStore = cloneDeep(this.optionsStore);
        }
        this.fetchStock();
      });
    },
    fetchStock: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      let listStoreId = [];
      if (this.valueStore.length) {
        listStoreId = this.valueStore.map(({ code }) => code);
      }

      const params = {
        page: this.page,
        pageSize: 10,
        searchDesciption: this.searchDesciption,
        searchCode: this.searchCode,
        searchFromDay: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        searchToDay: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        type: this.type,
        searchProduct: this.searchProduct,
        listStoreId: listStoreId,
        companyId: this.selectedCompany,
        mode: 1,
      };

      ApiService.query('stocks', { params })
        .then((response) => {
          const stockResponse = response.data.data.result;
          this.totalPages = response.data.data.totalPages;
          this.currentPage = response.data.data.currentPage;
          this.totalItems = response.data.data.totalItems;
          this.items = stockResponse.map((item, index) => {
            return {
              ...item,
              count:
                this.currentPage >= 2
                  ? index + 1 + this.currentPage * 10 - 10
                  : index + 1,
              importDate: moment(item.importDate).format('DD/MM/YYYY'),
              exportDate: moment(item.exportDate).format('DD/MM/YYYY'),
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      const { href } = this.$router.resolve({
        name: 'update-import-trade-in',
        query: { id: item.id },
      });
      window.open(href, '_blank');
    },
    printItem: function (item) {
      this.$router.push({
        name: 'print-import-trade-in',
        query: { id: item.id, type: 2 },
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phiếu nhập kho thu cũ!',
        text: 'Bạn có chắc muốn xóa phiếu nhập kho thu cũ này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`stock-slips/${item.id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchStock();
        } else {
          this.makeToastFailure(data.message);
        }
      });
    },
    linkToBill: function (item) {
      let nameRouter = 'list-return-bill';
      let query = { id: item.relateStockSlipId };
      if (item.billType === 5) {
        nameRouter = 'update-transfer-stock';
        query.id = item.transferStockSlipId;
      }
      this.$router.push({
        name: nameRouter,
        query: query,
      });
    },
    fetchProduct() {
      this.optionsProduct[0].data = [];
      ApiService.get(
        `productSearch?searchProduct=${this.searchProduct}&storeId=&includeParent=true`,
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [...this.optionsProduct[0].data];
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, TIME_TRIGGER),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-import-trade-in',
      });
      this.fetchStock();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    onSelectStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = true;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },

    onRemoveStore(option) {
      let index = this.optionsStore.findIndex(
        (item) => item.name == option.name,
      );
      this.optionsStore[index].checked = false;
      this.filterdOptionsStore = cloneDeep(this.optionsStore);
    },
    onInputStoreChange(textInput = '') {
      this.searchStore(textInput);
    },
    searchStore(textInput) {
      let options = cloneDeep(this.optionsStore);
      if (!textInput || !textInput.trim().length) {
        this.filterdOptionsStore = map(options, (obj) => {
          return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
        });
        return;
      }

      const indexChooseAll = options.findIndex((prop) => prop.id === -1);

      if (indexChooseAll > -1) {
        options.splice(indexChooseAll, 1);
      }

      options = map(options, (obj) => {
        return assign(obj, find(this.filterdOptionsStore, { id: obj.id }));
      });

      this.filterdOptionsStore = this.fitlerOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },
    fitlerOptionsBy(items, textInput, prop, limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
    customLabelStore(option) {
      return `${option.name}`;
    },
    fetchCompany: async function () {
      this.listCompany = [];
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Tất cả',
        });
      });
    },
    onChangeCompany() {
      this.valueStore = [];
      this.fetchStore();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu thu cũ', route: 'import-trade-in' },
      { title: 'Danh sách phiếu thu cũ' },
    ]);
  },
  created() {
    this.fetchStore();
    this.fetchCompany();
    this.fetchStoreByUser();
  },
  components: {
    KTCodePreview,
    Autosuggest,
    VclTable,
    Loader,
    BDropdownCustom,
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
  },
};
</script>

<style lang="scss">
.list-import-trade-in {
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }

  .example {
    border: 1px solid #ddd;
    margin-bottom: 2em;
  }

  code,
  pre {
    margin: 1em 0;
    padding: 1em;
    border: 1px solid #bbb;
    display: block;
    background: #ddd;
    border-radius: 3px;
  }

  .settings {
    margin: 2em 0;
    border-top: 1px solid #bbb;
    background: #eee;
  }

  .form-group {
    margin-bottom: 1em;
  }

  .btn.btn-icon.btn-sm,
  .btn-group-sm > .btn.btn-icon {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .sttClass {
    width: 20px;
  }

  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
    color: transparent;
  }

  .productCode:hover {
    text-decoration: underline;
  }
  .test {
    position: absolute;
    right: 1vw;
  }
  .checkbox-label {
    display: block;
  }
}
</style>
